var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-end",attrs:{"color":_vm.$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4',"flat":"","tile":""}},[_c('div',{staticStyle:{"width":"80%"}}),_c('v-select',{staticClass:"pa-2",attrs:{"outlined":"","filled":"","dense":"","items":['This Month', 'Last Month', 'Today', 'Yesterday', 'This Year', 'Last Year', 'Custom Date']},on:{"input":function (dateRange) { return _vm.dateRangeChanged(dateRange); }},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Custom Date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"range":"","no-title":"","scrollable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.dateRangeChanged(_vm.date, 'customDate')}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.translate('Inventory Adjustments'))+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.items,"items-per-page":20},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.date)))]),_vm._v("  "),_c('span',{staticClass:"font-weight-medium v-chip v-size--small primary"},[_c('span',{staticClass:"v-chip__content"},[_vm._v(" "+_vm._s(_vm.timeAgo(item.date))+" ")])])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium v-chip v-size--small",class:item.type == 'Add' ? 'success' : 'error'},[_c('span',{staticClass:"v-chip__content"},[_vm._v(" "+_vm._s(item.type)+" ")])])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.amount)+" "+_vm._s(item.measurement_name)+" ")]}}])})],1),_c('div',{staticClass:"text-center"},[_c('downloadExcel',{staticClass:"elevation-0 v-btn v-size--small v-btn--outlined v-btn--tile",attrs:{"data":_vm.items,"fields":{
        id: 'id',
        date: 'date',
        type: 'type',
        product: 'product_name',
        amount: 'amount',
        magazine_id: 'magazine_id',
      }}},[_vm._v("   Export Excel    ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }